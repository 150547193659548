.challenges-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.challenges-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-primary1-blue80);
}
.challenges-navbar-interactive {
  width: 90%;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.challenges-logo {
  font-size: 2em;
  font-family: "Lato";
  font-weight: bold;
  text-transform: uppercase;
}
.challenges-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.challenges-burger-menu {
  display: none;
}
.challenges-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.challenges-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.challenges-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.challenges-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.challenges-logo1 {
  font-size: 2em;
  font-weight: bold;
}
.challenges-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.challenges-icon02 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.challenges-links {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.challenges-nav1 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.challenges-nav2 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.challenges-nav3 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.challenges-nav4 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.challenges-nav5 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.challenges-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.challenges-login {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
}
.challenges-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.challenges-register:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.challenges-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.challenges-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.challenges-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.challenges-container1 {
  width: 100%;
  height: 1304px;
  display: flex;
  align-items: flex-start;
}
.challenges-sidebar {
  width: 300px;
  height: 700px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.challenges-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.challenges-text {
  margin-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-primary1-blue60);
}
.challenges-text1 {
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
}
.challenges-features {
  width: 100%;
  height: 736px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.challenges-text4 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.challenges-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .challenges-text4 {
    align-self: center;
  }
  .challenges-container2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .challenges-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .challenges-desktop-menu {
    display: none;
  }
  .challenges-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .challenges-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .challenges-nav1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .challenges-nav2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .challenges-nav3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .challenges-nav4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .challenges-nav5 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .challenges-sidebar {
    height: 70vh;
  }
  .challenges-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .challenges-navbar-interactive {
    width: 90%;
    padding: var(--dl-space-space-unit);
  }
  .challenges-logo {
    font-size: 1.5em;
  }
  .challenges-mobile-menu {
    padding: 16px;
  }
  .challenges-logo1 {
    font-size: 1.5em;
  }
  .challenges-icon02 {
    fill: var(--dl-color-gray-900);
  }
  .challenges-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .challenges-container2 {
    grid-gap: var(--dl-space-space-halfunit);
  }
}
