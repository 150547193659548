.feature-card11-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card11-feature-card:hover {
  transform: scale(1.02);
}
.feature-card11-text {
  margin-bottom: var(--dl-space-space-twounits);
}
.feature-card11-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.feature-card11-icon {
  width: 24px;
  height: 24px;
  align-self: flex-end;
}








